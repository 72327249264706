import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import './styles.scss'

const Section = loadable(() => import('/src/components/Section'))

const Steps = () => {
    return (
        <Section className="c-steps">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <h2>
                        Let Compare The Quote find Life Insurance that's right
                        for you and your family...
                    </h2>

                    <div className="c-section__content">
                        <div className="row">
                            <div className="col-lg-4 c-steps__column">
                                <div className="c-steps__column-inner">
                                    <StaticImage
                                        src="../../images/illustrations/form.png"
                                        alt="Illustration of man filling in form"
                                        className="c-steps__image"
                                        objectFit={'contain'}
                                    />
                                    <div>
                                        <h3>Complete Our Form</h3>
                                        <p>
                                            Fill out our quick form so we can
                                            match your specific requirements
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 c-steps__column">
                                <div className="c-steps__column-inner">
                                    <StaticImage
                                        src="../../images/illustrations/call-centre.png"
                                        alt="Illustration of woman on phone to call centre"
                                        className="c-steps__image"
                                        objectFit={'contain'}
                                    />
                                    <div>
                                        <h3>Compare Quotes</h3>
                                        <p>
                                            Compare the UK's top insurers
                                            through a regulated broker
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 c-steps__column">
                                <div className="c-steps__column-inner">
                                    <StaticImage
                                        src="../../images/illustrations/secure-data.png"
                                        alt="Illustration of man using laptop"
                                        className="c-steps__image"
                                        objectFit={'contain'}
                                    />
                                    <div>
                                        <h3>Get Covered</h3>
                                        <p>
                                            If you're happy you can be fully
                                            covered within 24 hours - it's that
                                            easy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Steps
